import React from 'react';
import { isEmpty, isNil } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { useSellerData, useSellerAiModels, FederatedSeller } from '@fiverr-private/seller_pages_toolbox';
import { AiModelsCarousel } from '@fiverr-private/go_shared_ui';

interface AiModelsCarouselWrapperProps extends Partial<React.ComponentProps<typeof AiModelsCarousel>> {}
export const AiModelsCarouselWrapper: React.FC<AiModelsCarouselWrapperProps> = (props) => {
    const { sellerName } = useSellerData(selectSellerSlice);
    const sellerAIModels = useSellerAiModels();

    if (isNil(sellerAIModels) || isEmpty(sellerAIModels)) return null;

    const aiModelCards = sellerAIModels.map(({ id, name, price }) => ({
        id,
        title: name,
        price,
        buttonHref: pathfinder('ai_hub_playground_create_tab_new_model_conversation', {
            seller_username: sellerName,
            model_slug: id,
        }),
    }));

    return <AiModelsCarousel {...props} aiModels={aiModelCards} />;
};

const selectSellerSlice = (seller: FederatedSeller) => ({
    sellerName: seller.user.name,
});
