import React from 'react';
import { isEmpty } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { AiModelsCarousel } from '@fiverr-private/go_shared_ui';
import { Container } from '@fiverr-private/layout_components';
import { SellerAiModel } from '../../../gql-operations/gql-generated';

interface AiModelBadgeTooltipProps {
    aiModels: SellerAiModel[];
    sellerName: string;
}
export const AiModelBadgeTooltip: React.FC<AiModelBadgeTooltipProps> = ({ aiModels, sellerName }) => {
    if (isEmpty(aiModels)) return null;

    const aiModelCards = aiModels.map(({ id }) => ({
        id,
        buttonHref: pathfinder('ai_hub_playground_create_tab_new_model_conversation', {
            seller_username: sellerName,
            model_slug: id,
        }),
    }));

    return (
        <Container height="264px" width="670px" backgroundColor="black" padding="4" borderRadius="lg">
            <AiModelsCarousel aiModels={aiModelCards} description="placeholder" />
        </Container>
    );
};
