import React, { useCallback, useRef, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { useAtom } from 'jotai';
import classnames from 'classnames';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { Section } from '@fiverr-private/fit';
import { Divider, Container } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_LOCALE } from '@fiverr-private/fiverr_context/lib/constants';
import { logger } from '@fiverr-private/obs';
import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '@fiverr-private/i18n-react';
import {
    SellerOverview,
    AboutMe,
    useSellerData,
    NotableClients,
    Expertise,
    ContactCard,
    VacationMessageBanner,
    ButtonsGroup,
    usePortfolio,
    ProOverview,
    SellerPageFederatedSeller,
    FederatedSeller,
    useSellerDataUpdater,
    UGCTranslateButton,
    Testimonials,
    WorkExperienceSection,
    useWorkExperience,
    useSellerAiModels,
} from '@fiverr-private/seller_pages_toolbox';
import { PAGE_NAME } from '../../../../configuration/constants';
import { ROLLOUTS } from '../../../server/middlewares/addRolloutsData/constants';
import { usePortfolioEventsReporter } from '../../hooks/usePortfolioEventsReporter';
import { useShouldRenderSaveSellerButton } from '../../hooks/useShouldRenderSaveSellerButton';
import { useSellerReviews } from '../../hooks/useSellerReviews';
import { rolloutsAtom } from '../../atoms';
import { LearnMoreButton } from '../LearnMoreButton';
import { SaveSellerButton } from '../SaveSellerButton';
import { StickyHeader } from '../StickyHeader';
import { ContactSellerModalWrapper } from '../ContactSellerModal';
import { createTabId, scrollToTab } from '../StickyHeader/tab-names';
import { SellerReviews } from '../SellerReviews';
import { StickyFooter } from '../StickyFooter';
import { SellerPagePortfolio } from '../SellerPagePortfolio';
import { Gigs } from '../Gigs';
import { HourlyRatesSection } from '../HourlyRatesSection';
import { useContactSellerModalUpdater } from '../ContactSellerModal/hooks/useContactSellerModal';
import { AiModelsCarouselWrapper } from '../AiModelComponents/AiModelsCarouselWrapper';
import { SELLER_PAGE_SIGNING_TRIGGER_IDS, SELLER_PAGE_SIGNING_TRIGGER_PLACEMENTS } from './constants';

import styles from './index.module.scss';

const onRenderError = (originalError) => {
    const err = new Error('Failed while rendering the SellerPage Component');
    logger.error(err, {
        cause: originalError,
    });
};

export const SellerPage = () => {
    const { locale } = getContext();
    const bannerRef = useRef<HTMLDivElement>(null);
    const setShowModal = useContactSellerModalUpdater();
    const { showPortfolio } = usePortfolio();
    const sellerAIModels = useSellerAiModels();
    const { showWorkExperience } = useWorkExperience(true);
    const seller = useSellerData(selectSellerSlice);
    const setSellerData = useSellerDataUpdater();
    const reviews = useSellerReviews();
    const shouldRenderSaveSellerButton = useShouldRenderSaveSellerButton();
    usePortfolioEventsReporter();
    const [showTranslatedUGC, setShowTranslatedUGC] = useState(false);
    const [rollouts] = useAtom(rolloutsAtom);
    const inQualasNewLevelSystemRollout = rollouts[ROLLOUTS.QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION];

    const { description, isPro } = seller;
    const isDefaultLocale = locale === DEFAULT_LOCALE;
    const stringifiedSeller = JSON.stringify(seller);

    const onContactOpen = () => {
        setShowModal(true);
    };

    const showAiModelCarousel = !isEmpty(sellerAIModels) && !isNil(sellerAIModels);
    const hasReviews = !!reviews?.buying_reviews?.reviews?.length || !!reviews?.selling_reviews?.reviews?.length;
    const hasTestimonials = (seller?.testimonials?.length || 0) > 0;
    const observableRef = useRef(null);

    const onUGCTranslateSuccess = useCallback(
        (
            {
                oneLinerTitle = '',
                certifications = [],
                description = '',
                vacationMessage = '',
                notableClients = [],
            } = {} as any,
            showTranslatedUGC: boolean
        ) => {
            const seller = JSON.parse(stringifiedSeller);

            const existingCertifications = seller?.certifications;
            const updatedCertifications = Array.isArray(existingCertifications)
                ? existingCertifications.map((cert, i) => ({
                      ...cert,
                      certificationName: certifications[i],
                  }))
                : [];

            const existingNotableClients = seller?.notableClients;
            const updatedNotableClients = Array.isArray(existingNotableClients)
                ? existingNotableClients.map((client, i) => ({
                      ...client,
                      description: notableClients[i]?.description,
                      company: client.company && {
                          ...client.company,
                          description: notableClients[i]?.companyDescription,
                      },
                  }))
                : [];

            setSellerData((sellerToUpdate: FederatedSeller) => {
                sellerToUpdate.description = description;
                sellerToUpdate.oneLinerTitle = oneLinerTitle;
                sellerToUpdate.certifications = updatedCertifications;
                sellerToUpdate.notableClients = updatedNotableClients;
                sellerToUpdate.vacation = seller.vacation && {
                    ...(seller?.vacation || {}),
                    awayMessage: vacationMessage,
                };
            });

            setShowTranslatedUGC(showTranslatedUGC);
        },
        [stringifiedSeller, setSellerData]
    );

    const getUgc = useCallback(() => {
        const seller = JSON.parse(stringifiedSeller);

        const notableClientsArr =
            seller.notableClients && seller.notableClients.length > 0 ? seller.notableClients : [];

        return {
            description: seller?.description,
            certifications: seller?.certifications?.map((cert) => cert.certificationName) || [],
            oneLinerTitle: seller.oneLinerTitle,
            vacationMessage: seller?.vacation?.awayMessage,
            notableClients: notableClientsArr.map((client) => ({
                description: client.description || '',
                companyDescription: client.company?.description || '',
            })),
        };
    }, [stringifiedSeller]);

    const onRatingsClick = useCallback(
        (close) => () => {
            scrollToTab({ tabId: StickyHeader.TabName.Reviews });
            close();
        },
        []
    );

    return (
        <ErrorBoundary onError={onRenderError}>
            <div className={classnames(styles.sellerPage, 'p-t-0')}>
                <StickyHeader observeHideRef={bannerRef} />
                <div ref={bannerRef} />
                <Section className="p-t-0 p-b-0">
                    <div className={classnames(styles.gridAboutSection, styles.withSecondColumn)}>
                        <div className={styles.firstColumn}>
                            <SellerOverview
                                showSellerLevel
                                className={classnames(styles.subHeaderOverview, 'm-b-40')}
                                showSellerLevelNewBadges={inQualasNewLevelSystemRollout}
                            />
                            <div
                                className={classnames(styles.firstColumnMaxWidth)}
                                id={createTabId({ tabId: StickyHeader.TabName.About })}
                            >
                                {!isDefaultLocale && (
                                    <div className="m-b-40">
                                        <UGCTranslateButton
                                            componentName={PAGE_NAME}
                                            sourceName="seller_page"
                                            translateURL={pathfinder('seller_page_ugc_translate')}
                                            getUgc={getUgc}
                                            onSuccess={onUGCTranslateSuccess}
                                        />
                                    </div>
                                )}
                                <VacationMessageBanner />
                                {isPro && <ProOverview className={styles.firstColumnMaxWidth} />}
                                {description && <AboutMe className="m-b-24" />}
                                <Expertise className="m-b-24" filterType="only_skills" />
                                <NotableClients wrapTitle className={styles.notableClients} titleSize="sm" />
                                <HourlyRatesSection className="m-t-40" freelancer_page_type="regular" />
                                <ButtonsGroup className={classnames(styles.hideMd, 'flex flex-start m-t-40')}>
                                    <LearnMoreButton onRatingsClick={onRatingsClick} />
                                    {shouldRenderSaveSellerButton && <SaveSellerButton />}
                                </ButtonsGroup>
                            </div>
                        </div>
                        <div
                            className={classnames(
                                styles.secondColumn,
                                styles.noCoverPhoto,
                                'm-t-24 p-t-8 flex flex-col flex-items-end'
                            )}
                        >
                            <ButtonsGroup className={styles.buttonsGroup}>
                                <LearnMoreButton onRatingsClick={onRatingsClick} className="text-trunc" />
                                {shouldRenderSaveSellerButton && <SaveSellerButton />}
                            </ButtonsGroup>
                            <ContactCard
                                onContactOpen={onContactOpen}
                                newView
                                triggerId={SELLER_PAGE_SIGNING_TRIGGER_IDS.CONTACT_SELLER}
                                triggerPlacement={SELLER_PAGE_SIGNING_TRIGGER_PLACEMENTS.SELLER_CONTACT_CARD}
                            />
                        </div>
                    </div>
                    <div id={createTabId({ tabId: StickyHeader.TabName.Services })}>
                        <Gigs showTranslatedUGC={showTranslatedUGC} />
                    </div>
                    {showAiModelCarousel && (
                        <Container height="395px" width="980px" marginTop="12">
                            <AiModelsCarouselWrapper
                                title={translate('seller_page_perseus.ai_models.carousel.title')}
                                description={translate('seller_page_perseus.ai_models.carousel.description')}
                            />
                        </Container>
                    )}
                    {showPortfolio && (
                        <div className={styles.portfolio} id={createTabId({ tabId: StickyHeader.TabName.Portfolio })}>
                            <SellerPagePortfolio />
                        </div>
                    )}
                    {showWorkExperience && (
                        <div
                            className={styles.workExperience}
                            id={createTabId({ tabId: StickyHeader.TabName.WorkExperience })}
                        >
                            <WorkExperienceSection />
                        </div>
                    )}
                </Section>
                {hasReviews && (
                    <Section className={classnames('p-t-0', { 'p-b-0': hasTestimonials })}>
                        <div
                            id={createTabId({ tabId: StickyHeader.TabName.Reviews })}
                            ref={observableRef}
                            className={classnames(styles.reviews, {
                                [styles.withTestimonials]: hasTestimonials,
                            })}
                        >
                            <SellerReviews />
                        </div>
                    </Section>
                )}
                {hasTestimonials && (
                    <Section className="p-t-0 p-b-0">
                        <Divider />
                        <Container
                            id={createTabId({ tabId: StickyHeader.TabName.Testimonials })}
                            marginTop={{ sm: '8', md: '12' }}
                        >
                            <Testimonials />
                        </Container>
                    </Section>
                )}
                <StickyFooter />
            </div>
            <ContactSellerModalWrapper />
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        certifications: seller.certifications,
        description: seller.description,
        isPro: seller.isPro,
        notableClients: seller.notableClients,
        oneLinerTitle: seller.oneLinerTitle,
        vacation: seller.vacation,
        testimonials: seller.testimonials,
        sellerAIModels: seller.sellerAIModels,
    };
}
